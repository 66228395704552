<template>
    <div class="min-h-screen bg-body-light dark:bg-zinc-950 isolate overflow-hidden flex flex-col justify-center items-center -mt-20 sm:-mt-10 pt-6 sm:pt-0 px-4 ">
<!--        <div class="absolute  inset-y-[16rem]  inset-x-[2rem] top-[22%] -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl" aria-hidden="true">
            <div class="aspect-[1108/632] w-[33rem] flex-none bg-gradient-to-r from-transparent to-transparent dark:from-emerald-500 dark:to-emerald-700 opacity-40" style="clip-path: polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)" />
        </div>-->
        <div class="w-full sm:max-w-md">
            <slot name="logo" />
        </div>

        <CardContainer :divide-y="false" class="w-full sm:max-w-md mt-6 px-6 py-6  backdrop-blur-lg !border-none ring-1 ring-zinc-500/10 dark:ring-white/5">
            <slot />
        </CardContainer>

        <slot name="footer" />
    </div>
</template>

<style>
.bg-dots-darker {
    background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.22676 0C1.91374 0 2.45351 0.539773 2.45351 1.22676C2.45351 1.91374 1.91374 2.45351 1.22676 2.45351C0.539773 2.45351 0 1.91374 0 1.22676C0 0.539773 0.539773 0 1.22676 0Z' fill='rgba(0,0,0,0.07)'/%3E%3C/svg%3E");
}
@media (prefers-color-scheme: dark) {
    .dark\:bg-dots-lighter {
        background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.22676 0C1.91374 0 2.45351 0.539773 2.45351 1.22676C2.45351 1.91374 1.91374 2.45351 1.22676 2.45351C0.539773 2.45351 0 1.91374 0 1.22676C0 0.539773 0.539773 0 1.22676 0Z' fill='rgba(255,255,255,0.07)'/%3E%3C/svg%3E");
    }
}
</style>
<script setup>
import CardContainer from "../Comps/CardContainer.vue";
import {onBeforeMount} from "vue";

onBeforeMount(() => {
    //add class to document body
    document.body.classList.remove('bg-zinc-950');
    document.body.classList.add('bg-body-light', 'dark:bg-zinc-950');
});
</script>
